import React, { useState } from "react";
import clsx from "clsx";
import { Container, Text, Icon } from "@atoms";
import { m, AnimatePresence } from "framer-motion";

const FlowChart = ({
  bgColor,
  copy,
  className: _className,
  items,
  paddingBottom,
  paddingTop,
}) => {
  const [active, setActive] = useState(0);

  const { steps } = items[active];

  const defaults = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.3,
  };

  const block = {
    enter: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 20,
      opacity: 0,
    },
  };

  return (
    <section
      className={clsx("relative z-20 pb-16 pt-16 md:pb-32", {
        "md:pt-32": paddingTop,
        "bg-midnight": bgColor === "midnight",
        "bg-white": bgColor === "white",
      })}
    >
      <Container>
        <div className="flex flex-col items-center">
          {copy && (
            <div className="mx-auto max-w-3xl">
              <Text
                richText
                className={clsx(
                  "text-center prose-p:text-lg lg:prose-p:text-xl",
                  {
                    "prose-dark": bgColor === "midnight",
                  }
                )}
              >
                {copy}
              </Text>
            </div>
          )}
        </div>
        {!!items?.length && (
          <>
            <div className="relative mt-16 flex flex-wrap justify-center">
              {items.map((item, i) => {
                const { uid, category } = item;
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${category}--${i}`}
                    className={clsx("relative flex justify-center", {
                      "w-full": i === 0,
                      "-mt-16 md:-mt-28": i !== 0,
                      "-mr-8 md:-mr-12": i === 1,
                      "-ml-8 md:-ml-12": i === 2,
                    })}
                  >
                    <m.div
                      key={uid}
                      tabIndex={0}
                      onTap={() => setActive(i)}
                      className={clsx(
                        "flex aspect-square w-40 cursor-pointer items-center justify-center rounded-full border border-mint text-center duration-300 hover:bg-mint/20 md:w-64",
                        {
                          "bg-mint/20": active === i,
                        }
                      )}
                    >
                      <Text
                        variant="h7"
                        className={clsx(
                          "break-word relative max-w-[6rem] md:max-w-[8rem]",
                          {
                            "text-white": bgColor === "midnight",
                            "-top-8 md:-top-12": i === 0,
                            "-bottom-6 -left-6 md:-bottom-10 md:-left-10":
                              i === 1,
                            "-bottom-6 -right-6 md:-bottom-10 md:-right-10":
                              i === 2,
                          }
                        )}
                      >
                        {category}
                      </Text>
                    </m.div>
                  </div>
                );
              })}
              <Icon
                name={bgColor === "white" ? "logoMarkDark" : "logoMarkLight"}
                className="absolute top-[51%] h-5 w-5 md:h-10 md:w-10"
              />
            </div>
            <div className="overflow mt-16 flex flex-wrap justify-center gap-8">
              <AnimatePresence exitBeforeEnter>
                {steps.map((s, ii) => {
                  const { step } = s;
                  const delay = ii * 0.05;
                  return (
                    <m.div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${step.slice(0, 10)}--${ii}`}
                      initial="exit"
                      animate="enter"
                      exit="exit"
                      transition={{ ...defaults, delay }}
                      variants={block}
                      className="flex w-full flex-col items-center gap-3 border border-mint p-3 text-center sm:flex-[0_1_calc(50%-1rem)] md:flex-[0_1_calc(33.33%-1.34rem)] lg:flex-[0_1_calc(25%-1.5rem)]"
                    >
                      <Text
                        variant="h5"
                        className={clsx({
                          "text-carnation": bgColor === "midnight",
                        })}
                      >
                        {ii + 1}
                      </Text>
                      <Text
                        variant="h7"
                        className={clsx({
                          "text-white": bgColor === "midnight",
                        })}
                      >
                        {step}
                      </Text>
                    </m.div>
                  );
                })}
              </AnimatePresence>
            </div>
          </>
        )}
      </Container>
    </section>
  );
};

FlowChart.defaultProps = {};

export default FlowChart;
